<template>
  <div class="demo-spacing-0 mb-2">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        For any queries, please user the below hotline
      </h4>
      <div class="alert-body">
        Phone No:
        <b-link class="alert-link">
          +254 0720 375 045
        </b-link>
        Email:
        <b-link class="alert-link">
          info@kakamega.go.ke
        </b-link>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert, BLink } from 'bootstrap-vue'

export default {
  components: {
    BAlert,
    BLink,
  },
}
</script>
